import React from "react";


class Manual extends React.Component {
  render() {
    return(
      <div class="container uputstvo">
          <div class="col-sm-12"> 
              <div class="row" style={{marginBottom: "40px"}}>
                <h2><strong>Upute za tarot čitanje</strong></h2>
                <p><strong>#1: Udahnite i izdahnite nekoliko puta!</strong></p>
                <p>Prije nego odaberete kartu, udahnite i izdahnite duboko nekoliko puta. 
                  Pokušajte opustiti svoje tijelo i utišati svoje misli. 
                  Dopustite svojim mislima da dolaze i odlaze neko vrijeme ne držeći se za njih.</p>

                <p><strong>#2: Samo pitanje!</strong></p>
                <p>Fokusirajte se trenutak, dva na pitanje za koje želite doznati odgovor, ili situaciju o kojoj želite dobiti informacije PRIJE nego odaberete karte. 
                  Što efikasnije se možete opustiti i fokusirati na pitanje ili događaj koji vas zanima, to će točnije i od više pomoći biti čitanje karti.</p>

                <p><strong>#3: Održavajte fokus!</strong></p>
                <p>Odsutnost pozornosti pri odabiru karti neće donijeti dobre rezultate. 
                  Fokusiranje namjere u ulaženju neophodnog sinkroniciteta dati će maksimum vrijednosti u čitanju karti. 
                  Uzmite si dovoljno vremena za opuštanje prije početka odabira karti. Biti ćete zadivljeni u razlici koju ćete uvidjeti.</p>

                <p><strong>#4: Budite precizni!</strong></p>
                <p>Što je veća preciznost i fokus na pitanju i odgovor će biti prilagođeniji i vrijedniji. 
                  Ponavljamo, to je funkcija sinkroniciteta. 
                  Ukoliko je vaše pitanje nejasno formulirano, kako vam karte mogu dati jasne odgovore? 
                  Ukoliko pristupajući odabiru karti razmišljate o 30 drugih pitanja i dešavanja, odgovor koji ćete dobiti često neće imati smisla. 
                  Jasnoća i čist fokus će vam pomoći da dobijete jasno definirani odgovor.</p>

                <p><strong>#5: Čitajte između redaka!</strong></p>
                <p>Ukoliko doslovno napisan tekst ne odgovara vašem pitanju ili okolnosti, obično je potrebno malo pročitati između redaka, te na taj način prilagoditi čitanje osobnoj situaciji.</p>

                <p><strong>#6: Prihvatite prvo čitanje!</strong></p>
                <p>Ovo je specijalno napravljen sustav i primamljivo je odmah ponoviti čitanje, ukoliko je odgovor koji ste dobili ono što niste željeli pročitati. 
                  Međutim, naš savjet je NE, ne čitajte karte iznova i iznova. 
                  Radije ostavite karte, slijedite savjete o jasnoći i fokusu i vjerujte da će prvo čitanje uvjek biti najprikladnije.</p>

                <p><strong>#7: Čuvajte se pretjerane uporabe!</strong></p>
                <p>Savjetovanje kartama više puta tijekom dana, iz dana u dan je potpuno poražavajuće jer ponavljanje čitanja prerano, za isto postavljeno pitanje, obično stvara više konfuzije, a ne više jasnoće. 
                  Dobro i jedino pravilo – dok su god okolnosti u kojima ste iste ili su pitanja ista, ne pitajte ponovno. 
                  Jedino ispravno u toj situaciji je pozvati naše stručnjake, da vam kompletno analiziraju i protumače karte koje ste dobili, ili jednostavno potražite potpuno otvaranje tarota sa detaljnijim očitavanjem.</p>

                <p><strong>#8: Nemojte se plašiti negativnih karti!</strong></p>
                <p>Neke karte su prilično zahtjevne za čitanje, mogu odražavati negativnosti, međutim ovdje nema razloga za strah jer su karte koje otvarate odraz trenutačnih utjecaja u vašem životu i često morate napraviti usporedbu sa ostalim kartama koje su vam se otvorile. 
                  Da li je stanje karti apsolutno negativno ili pozitivno, možete dobiti detaljnom analizom …</p>
              </div>
          </div>
      </div>
    );
  }
}
export default Manual;