import React from "react";


class Terms extends React.Component {
  render() {
    return(
      <div class="container uputstvo">
          <div class="col-sm-12"> 
              <div class="row">
                <h2><strong>Uvjeti korištenja:</strong></h2>
                
                <p>moj-tarot.net mogu koristiti osobe koje su navršile 18 godina. Pristupanjem portalu suglasni ste da razumijete sve odredbe Uvjeta korištenja i da ste navršili 18 godina.
                Usluga je namjenjena samo za zabavu. 
                Tekstovi koji se nalaze na web stranici su objekt autorskog prava i zaštićeni su Zakonom o autorskom i srodnim pravima. Servis je dostupan samo korisnicima unutar Hrvatske. 
                WAP/GPRS promet plaća se prema cjeniku operatera. Cijena primljene poruke sa broja 862862 iznosi 30kn/SMS. Nije na osnovu pretplate. Plaćanje je jednokratno. Primljen rezultat je dostupan samo na web stranici.
                Prihvaćanjem Uvjeta korištenja korisnici su suglasni da im moj-tarot.net povremeno može dostavljati reklamne materijale SMS ili MMS porukom isključivo na brojeve mobilnih uređaja iz korisničke baze.</p>

                <p>Ova stranica za pružanje svojih usluga koristi kolačiće. Korištenjem ove web stranice pristajete na upotrebu kolačića. Možete ograničiti upotrebu kolačića u svakom trenutku pomoću internetskih postavki.</p>
            </div>
          </div>
      </div>
    );
  }
}
export default Terms;