import React from "react";


class Home extends React.Component {
    render() {
      return(
        <div className="container page-wrapper">
            <div className="col-sm-12 col-xs-12">
                <div className="col-sm-4 col-xs-12">
                    <div className="drevni-tarot-bkg">
                        <div className="drevni-tarot-tekst">
                            <h1><strong>Drevni Tarot</strong></h1>
                            <p>Pronalazak drevne knjige o tarotu napisane u XVII stoljeću negdje na sjeveru Europe donio je novi pogled na tumačenja prošlosti, sadašnjosti i budućnosti i otvorio vrata duhovnog svijeta i kozmičkih tajni.</p>
                            <p>Koristeći simbole, alegorijske slike i ezoterijske jezike proizašle iz drevne knjige uspjeli smo stvoriti jedinstvene tarot karte kroz koje se mogu predočiti događaji zapisani na životnom putu svake osobe.</p>
                            <p><strong>Sve što trebate učiniti jest biti voljni otvoriti oči, prigrliti ponuđenu mudrost i započeti svoje putovanje. Neka karte budu vaš vodič ka duhovnom svijetu i prilika kroz koju možete popraviti svoj život.</strong></p>
                        </div>
                    </div>
                    <div className="drevni-tarot-dugme">
                        <a href="/tarot"><p>Pogledajte što karte otkrivaju<br/>za Vas!</p></a>
                    </div>
                </div>
                <div className="col-sm-8 col-xs-12 book-home">
                    <a href="/tarot"><img alt="" src="img/book.png" className="img-responsive"/></a>
                </div>
            </div>
        </div>
      );
    }
}
export default Home;