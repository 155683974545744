import React from "react";


class Help extends React.Component {
  render() {
    return(
      <div class="container uputstvo">
          <div class="col-sm-12"> 
              <div class="row">
                <h2><strong>Pomoć</strong></h2>
                <p>
                  Služba za korisnike: radnim danom od 09:00 do 17:00 sati na: comparo.hr@ccsupport.biz tel:042500871
                  Organizator usluge: Comparo Media d.o.o., Tometići 15a, 51215, Kastav, OIB: 80948414608
                  Tehnička podrška (davatelj usluge): NTH Mobile d.o.o., Međimurska 28, 42000 Varaždin: OIB: 12385860076, Tel: 042500870
                </p>
              </div>
          </div>
      </div>
    );
  }
}
export default Help;