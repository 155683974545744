import React from "react";
import { withRouter } from "react-router";
import OneSignal from 'react-onesignal';
import './Tarot.css';

class Tarot extends React.Component {
  constructor(props) {
    super(props);

    OneSignal.initialize('2368141f-4e3c-4460-82cb-20ccb66a5da5');

    const { match /*, location, history */} = props;
    this.state = {smsCode: match["params"]["smsCode"]};

    this.state = {
      version: match["params"]["version"] ? match["params"]["version"] : null,
      selectedCards: this._checkHasPreviousResult() ? JSON.parse(localStorage.getItem('selectedCards')) : [], 
      resultCounddownValue: this._checkHasPreviousResult() ? localStorage.getItem('resultCounddownValue') : 60, 
      resultCountdownIntervalId: null,
      resultSMSCode: null
    };
    
    this.selectCard = this.selectCard.bind(this);

    this.rootRef = null;
    this.titleRef = null;
    this.titleResultRef = null;
    this.cardsWrapperRef = null;
    this.cardRefs = {};
    this.cardPlaceholderRefs = {};

    this.resultSubTitleRef = null;
    this.resultDescRef = null;
    this.resultQuestionRef = null;
    this.resultAnswerRef = null;
    this.resultSMSRef = null;
    this.resultSMSMobileRef = null;
  }
  _checkHasPreviousResult() {
    var selectedCards = localStorage.getItem('selectedCards');
    var selectedCardsExpire = localStorage.getItem('selectedCardsExpire');
    if(selectedCards != null && selectedCardsExpire != null) {
      selectedCardsExpire = new Date(JSON.parse(selectedCardsExpire));
      if(selectedCardsExpire > new Date( Date.now() - 10 * 60000 )) {
        return true;
      }
    }
  }
  componentDidMount() {
    if(this.state.selectedCards.length > 0) {
      this.rootRef.classList.add('no-anim');
      let cardPosition = 1;
      for(const selectedCardIndex of this.state.selectedCards) {
        this._adjustSelectedCard(cardPosition, this.cardRefs[selectedCardIndex], this.cardPlaceholderRefs[cardPosition]);
        cardPosition++;
      }
      var selectedCardsResult = localStorage.getItem('selectedCardsResult');
      this.displayResults(JSON.parse(selectedCardsResult), true);
    }
  }
  componentWillUnmount() {
    if(this.state.resultCountdownIntervalId != null) {
      clearInterval(this.state.resultCountdownIntervalId);
    }
  }
  render() {
    return (
      <div className="container izaberite-karte" ref={(instance)=>{this.rootRef = instance;}}>
          <div className="col-sm-12 wrapper"> 
              {this.state.version != null && this.state.version === "2"
                ? <div className="title-wrapper-2">
                    <h2 className="title" ref={(instance)=>{this.titleRef = instance;}}><span style={{fontSize: '26px', color: 'white', lineHeight: '40px'}}>Besplatno čitanje tarota!</span><br/> Saznajte odgovore koje trebate<br/> Ljubav - posao - novac - obitelj - planovi - promjena u životu<br/><span style={{fontSize: '20px', color: 'white', lineHeight: '40px'}}> Odaberite 3 karte ispod</span></h2>
                    <h2 className="title title-result" ref={(instance)=>{this.titleResultRef = instance;}}>Odabrali ste tarot karte u jedinstvenom redosljedu<br/> i one otkrivaju samo Vaš put</h2>
                  </div>
                : <div className="title-wrapper">
                    <h2 className="title" ref={(instance)=>{this.titleRef = instance;}}>Dišite duboko, usredotočite se na pitanje, održavajte fokus,<br/> budite precizni, i kada budete spremni, odaberite 3 karte ispod</h2>
                    <h2 className="title title-result" ref={(instance)=>{this.titleResultRef = instance;}}>Odabrali ste tarot karte u jedinstvenom redosljedu<br/> i one otkrivaju samo Vaš put</h2>
                  </div>
              }
              <div className="kniga-dole">
                  <img alt="" src="/img/book3.png" className="img-responsive"/>
              </div>
              <div className="cards-wrapper" ref={(instance)=>{this.cardsWrapperRef = instance;}}>
                <div className="cards-row">
                    <div style={{top: "5.4%", left: "19%"}} alt="" ref={(instance)=>{this.cardRefs[1] = instance;}} onClick={this.selectCard} index="1" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "23.7%"}} alt="" ref={(instance)=>{this.cardRefs[2] = instance;}} onClick={this.selectCard} index="2" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "28.4%"}} alt="" ref={(instance)=>{this.cardRefs[3] = instance;}} onClick={this.selectCard} index="3" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "33.1%"}} alt="" ref={(instance)=>{this.cardRefs[4] = instance;}} onClick={this.selectCard} index="4" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "37.8%"}} alt="" ref={(instance)=>{this.cardRefs[5] = instance;}} onClick={this.selectCard} index="5" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "42.5%"}} alt="" ref={(instance)=>{this.cardRefs[6] = instance;}} onClick={this.selectCard} index="6" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "47.2%"}} alt="" ref={(instance)=>{this.cardRefs[7] = instance;}} onClick={this.selectCard} index="7" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "51.9%"}} alt="" ref={(instance)=>{this.cardRefs[8] = instance;}} onClick={this.selectCard} index="8" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "56.6%"}} alt="" ref={(instance)=>{this.cardRefs[9] = instance;}} onClick={this.selectCard} index="9" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "61.3%"}} alt="" ref={(instance)=>{this.cardRefs[10] = instance;}} onClick={this.selectCard} index="10" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "66%"}} alt="" ref={(instance)=>{this.cardRefs[11] = instance;}} onClick={this.selectCard} index="11" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                    <div style={{top: "5.4%", left: "70.7%"}} alt="" ref={(instance)=>{this.cardRefs[12] = instance;}} onClick={this.selectCard} index="12" className="card">
                      <div className="card-inner">
                        <div className="card-flip-front"><img alt="" className="img-responsive" src="/img/cards/card-back.png"/></div>
                        <div className="card-flip-back"><img alt="" className="img-responsive"/></div>
                      </div>
                      <div className="card-title"></div>
                    </div>
                </div>
                <div className="cards-row">
                    <img alt="" style={{top: "39.5%", left: "33%"}} ref={(instance)=>{this.cardPlaceholderRefs[1] = instance;}} src="/img/cards/card-placeholder.png" className="img-responsive card-placeholder"/>
                    <img alt="" style={{top: "39.5%", left: "45%"}} ref={(instance)=>{this.cardPlaceholderRefs[2] = instance;}} src="/img/cards/card-placeholder.png" className="img-responsive card-placeholder"/>
                    <img alt="" style={{top: "39.5%", left: "57%"}} ref={(instance)=>{this.cardPlaceholderRefs[3] = instance;}} src="/img/cards/card-placeholder.png" className="img-responsive card-placeholder"/>
                </div>
              </div>
              <div className="analiza-wrapper">
                <div className="analiza">
                  <div className="col-sm-12">
                    <h3 className="analiza-title hide-on-mobile">Vaša detaljna analiza karata je spremna i možete ju preuzeti u narednih: <span style={{color: "#e5a92c"}}>{this._formatCountdown(this.state.resultCounddownValue)}</span></h3>
                  </div>    
                  <div className="col-sm-12">
                      <div className="rezultat-tekst">
                          <div className="col-sm-5 col-sm-offset-1 left">
                              <p><strong ref={(instance)=>{this.resultSubTitleRef = instance;}}></strong><br/><span ref={(instance)=>{this.resultDescRef = instance;}}></span></p>
                              <p className="promene" ref={(instance)=>{this.resultQuestionRef = instance;}}></p>
                              <p><strong ref={(instance)=>{this.resultAnswerRef = instance;}}></strong></p>
                          </div>
                          <div className="col-sm-5 right hide-on-mobile">
                              <p className="top">Za detaljno tumačenje vaših karata <br/>Pošaljite osobni <span className="red">SMS KOD:</span></p>
                              <a ref={(instance)=>{this.resultSMSRef = instance;}} style={{display: "block", pointerEvents: "all", textDecoration: "none", outline: "none", userSelect: "none"}}>
                                <div className="button-kod">
                                    <h3 style={{fontSize: "24px", fontWeight: "bold"}}>{this.state.resultSMSCode}</h3>
                                    <h4 style={{fontSize: "16px"}}>na 862862</h4>
                                </div>
                              </a>
                              <p className="bottom">Pošaljite SMS-om vaš osobni kod {this.state.resultSMSCode} na 862862.<br/>
                                  Detaljno tumačenje iznosi 30kn, jednokratno. Karte koje ste sada odabrali odgovaraju trenutnoj situaciji i vašem duhovnom stanju stoga ne preporučujemo ponovno otvaranje karti.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-12 hide-on-desktop">
                    <h3 className="analiza-title">Preuzmite detaljnu analizu karata unutar: <span style={{color: "#e5a92c"}}>{this._formatCountdown(this.state.resultCounddownValue)}</span></h3>
                    <div className="col-sm-5 right">
                        <a href="" ref={(instance)=>{this.resultSMSMobileRef = instance;}} style={{display: "block", pointerEvents: "all", textDecoration: "none", outline: "none", userSelect: "none"}}>
                          <div className="button-kod">
                              <h3 style={{fontSize: "16px", fontWeight: "bold"}}>Želim detaljno tumačenje</h3>
                              <h4 style={{fontSize: "16px", fontWeight: "bold"}}>tarot karata</h4>
                          </div>
                        </a>
                        <p className="bottom" style={{fontSize: "12px", color: "white"}}> i pošaljite SMS-om vaš osobni kod {this.state.resultSMSCode} na 862862.<br/>
                            Detaljno tumačenje iznosi 30kn, jednokratno. Karte koje ste sada odabrali odgovaraju trenutnoj situaciji i vašem duhovnom stanju stoga ne preporučujemo ponovno otvaranje karti.
                        </p>
                    </div>
                  </div>
                  <div className="col-sm-12 hide-on-mobile">
                    <img className="img-responsive" src="/img/ivr-1920x300px.png" style={{margin: "64px 0"}}/>
                  </div>
                  <div className="col-sm-12 hide-on-desktop" style={{}}>
                    <img className="img-responsive" src="/img/ivr-300x250px.png" style={{width: "100%", height: "auto", marginTop: "332px", marginBottom: "32px"}}/>
                  </div>
                  <div className="col-sm-12 note">
                    <div>
                      <p><small>Usluga je namjenjena samo za zabavu. Služba za korisnike: 09:00 - 17:00 sati na 042500871 ili na comparo.hr@ccsupport.biz. Organizator usluge je: Comparo Media d.o.o., Tometići 15a, 51215, Kastav, OIB 80948414608. Tehnička služba: NTH Mobile d.o.o. Međimurska 28, 42 000 Varaždin, MB: 070096612, OIB 12385860076. Tekstovi koji se nalaze na sajtu su objekt autorskog prava i zaštićeni su Zakonom o autorskom i srodnim pravima. Servis je dostupan samo korisnicima unutar Hrvatske. WAP/GPRS promet plaća se prema cjeniku operatera. Cijena primljene poruke sa broja 862862 iznosi 30kn/SMS. NIJE na osnovu pretplate. Plaćanje je jednokratno. Primljen rezultat je dostupan samo na web stranici.</small></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    );
  }

  // _formatCountdown(s){return(s-(s%=60))/60+(9<s?'0:':':0')+s+':00'}
  _formatCountdown(s){
    var measuredTime = new Date(null);
    measuredTime.setSeconds(s); // specify value of SECONDS
    var hhmmss = measuredTime.toISOString().substring(14, 19);
    if(s <= 0) {
      return "00:00";
    }
    return hhmmss;
  }

  async selectCard(e) {
    const cardToSelect = e.currentTarget;
    const cardPlaceholder = this.cardPlaceholderRefs[this.state.selectedCards.length + 1];

    if(cardToSelect.classList.contains("card-selected")) return;

    const selectedCards = this.state.selectedCards;
    selectedCards.push(parseInt(cardToSelect.getAttribute("index")));
    this.setState({selectedCards: selectedCards});

    this._adjustSelectedCard(selectedCards.length, cardToSelect, cardPlaceholder);
    
    if(this.state.selectedCards.length === 3) {
      const res = await fetch((window.location.origin.includes('moj-tarot') 
        ? window.location.origin.replace("3000", "3010") 
        : 'http://moj-tarot.net') 
      + '/api/api.php?action=getTarotResult&cards=' + this.state.selectedCards.join(','));
      const response = await res.json();

      const result = response["result"];
      this.displayResults(result);
    }
  }

  _adjustSelectedCard(cardPosition, cardToSelect, cardPlaceholder) {
    cardToSelect.classList.add('card-selected');
    cardToSelect.classList.add('card-selected-' + cardPosition);
    cardToSelect.style["transition"] = "all 0.3s ease-in-out";

    var mq = window.matchMedia('(max-width:1023px)');
    if(mq.matches) {
        switch(cardPosition) {
          case 1:
            cardToSelect.setAttribute("style", "top: 41.5%; left: calc(100vw / 2 - (121px + (121px / 2))) !important; margin: 0;");
            break;
          case 2:
            cardToSelect.setAttribute("style", "top: 41.5%; left: calc(100vw / 2 - (121px / 2)) !important; margin: 0;");
            break;
          case 3:
            cardToSelect.setAttribute("style", "top: 41.5%; left: calc(100vw / 2 + (121px / 2)) !important; margin: 0;");
            break;
          default:
            break;
        }
    } else {
      cardToSelect.style["top"] = cardPlaceholder.style["top"];
      cardToSelect.style["left"] = cardPlaceholder.style["left"];
    }
    cardToSelect.style["position"] = "absolute";
  }

  async displayResults(result, resultIsPreExistent) {
    this.cardsWrapperRef.classList.add("cards-reveal");

    Object.entries(result["cards"]).forEach((card, index) => {
      const cardIndex = this.state.selectedCards[index];
      const cardSelected = this.cardRefs[cardIndex];
      const cardBackImgEl = cardSelected.querySelector(".card-flip-back img");
      const cardTitleEl = cardSelected.querySelector(".card-title");

      if(card["1"]["direction"] === "backward") {
        cardBackImgEl.parentNode.classList.add('backward');
      }

      cardTitleEl.innerHTML = card["1"]["name"];
      cardBackImgEl.setAttribute("src", card["1"]["src"]);
    }, this);

    this.resultSubTitleRef.innerHTML= result["subtitle"];
    this.resultDescRef.innerHTML= result["desc"];
    this.resultQuestionRef.innerHTML= result["question"];
    this.resultAnswerRef.innerHTML= result["answer"];
    
    this.resultSMSRef.setAttribute("href", "sms:862862?&body=" + result["sms"]);
    this.resultSMSMobileRef.setAttribute("href", "sms:862862?&body=" + result["sms"]);
    this.setState({resultSMSCode : result["sms"]});

    this.titleRef.style["opacity"] = 0;
    this.titleResultRef.style["opacity"] = 1;
    this.rootRef.classList.add("root-cards-revealed");
    this.cardsWrapperRef.classList.add("cards-revealed");

    const countdownIntervalId = setInterval(this._decrementResultCountdown.bind(this), 1000);
    this.setState({resultCountdownIntervalId : countdownIntervalId});

    if(!resultIsPreExistent) {
      localStorage.setItem('selectedCards', JSON.stringify(this.state.selectedCards));
      localStorage.setItem('selectedCardsResult', JSON.stringify(result));
      localStorage.setItem('selectedCardsExpire', JSON.stringify(Date.now()));
    }
  }

  _decrementResultCountdown() {
    this.setState({resultCounddownValue: this.state.resultCounddownValue - 1}, () => {
      localStorage.setItem("resultCounddownValue", this.state.resultCounddownValue);
    });
    if(this.state.resultCounddownValue <= 0) {
      clearInterval(this.state.resultCountdownIntervalId);
      this.setState({resultCountdownIntervalId: null});
    }
  }
}
export default withRouter(Tarot);