import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from './pages/home/Home';
import Tarot from './pages/tarot/Tarot';
import TarotDetail from './pages/tarot-detail/TarotDetail';
import Manual from "./pages/manual/Manual";
import Help from "./pages/help/Help";
import Terms from "./pages/terms/Terms";


export default function App() {
  return (
    <div>
      <Switch>
        <Route path="/tarot/:version">
          <Tarot />
        </Route>
        <Route path="/tarot">
          <Tarot />
        </Route>
        <Route path="/detaljna-analiza/:smsCode">
          <TarotDetail />
        </Route>
        <Route path="/usage">
          <Manual />
        </Route>
        <Route path="/help">
          <Help />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  );
}
