import React from "react";
import { withRouter } from "react-router";
import './TarotDetail.css';

class TerotDetail extends React.Component {
  constructor(props) {
    super(props);
    const { match /*, location, history */} = props;
    this.state = {smsCode: match["params"]["smsCode"]};

    this.tarotDetailDescRef = null;
    this.tarotDetailTextRef = null;
    this.tarotDetailCardsRef = null;

    const randomBannerIndex = this.randomIntFromInterval(0, 1);

    this.randomBanner = [
      {link: "https://click.clickcash.pro/5eda3e62ae271a0001972e79", imgSrc: "/img/banner1.jpg"},
      {link: "https://click.clickcash.pro/5eda3e62ae271a0001972e79", imgSrc: "/img/banner2.jpg"},
    ][randomBannerIndex]
  }
  componentDidMount() {
    this._getTarotDetail();
  }
  render() {
    return(
      <div className="container izaberite-karte izaberite-karte-detail">
          <div className="col-sm-12"> 
            <div className="row">
                  <h2 style={{marginTop: "20px"}}>Detaljna analiza Vaših karata</h2>
              </div>
              <div className="row">
                  <div className="karte">
                      <div className="col-xs-12">
                          <div className="col-md-6 col-md-offset-3 col-xm-12" ref={(instance)=>{this.tarotDetailCardsRef = instance;}}>
                              <div className="col-sm-4 col-xs-4">
                                  <img alt=""src="/img/kraljica.png" className="img-responsive card-image"/>
                                  <p className="card-title"></p>
                              </div>  
                              <div className="col-sm-4 col-xs-4">
                                  <img alt="" src="/img/kraljica.png" className="img-responsive card-image"/>
                                  <p className="card-title"></p>
                              </div>
                              <div className="col-sm-4 col-xs-4">
                                  <img alt="" src="/img/kraljica.png" className="img-responsive card-image"/>
                                  <p className="card-title"></p>
                              </div>
                          </div>  
                      </div>    
                  </div>
              </div>
              <div className="row">      
                  <div className="col-sm-12 col-md-8 col-md-offset-2">
                      <img alt="" src="/img/text-bkg-top.png" className="img-responsive"/>
                      <div className="detaljna-analiza">    
                          <p ref={(instance)=>{this.tarotDetailDescRef = instance;}}></p><br/>
                      </div>
                      <div className="detaljna-analiza">    
                          <p style={{fontWeight: "bold"}}>Detaljno objašnjenje:</p><br/>
                      </div>
                      <div className="detaljna-analiza">
                          <p ref={(instance)=>{this.tarotDetailTextRef = instance;}}></p>        
                      </div>
                      <img alt="" src="/img/text-bkg-bottom.png" className="img-responsive"/>
                  </div>
              </div> 
          
              <div className="row ad" align="center">
                  <a href={this.randomBanner.link} target="_blank"><img alt="" src={this.randomBanner.imgSrc} className="img-responsive"/></a>
              </div>
          </div>
      </div>
    );
  }

  async _getTarotDetail() {
    const res = await fetch(window.location.origin.replace("3000", "3010") + '/api/api.php?action=getTarotFullResult&smsCode=' + this.state.smsCode);
    const response = await res.json();

    const result = response["result"];

    this.tarotDetailDescRef.innerHTML = result["desc"];
    this.tarotDetailTextRef.innerHTML = result["text"];

    Object.entries(result["cards"]).forEach((card, index) => {
      const cardEl = this.tarotDetailCardsRef.children[index];
      cardEl.querySelector(".card-image").setAttribute("src", card["1"]["src"]);
      cardEl.querySelector(".card-title").innerHTML = card["1"]["name"];

      if(card["1"]["direction"] === "backward") {
        cardEl.querySelector(".card-image").classList.add('backward');
      }
    }, this);
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
export default withRouter(TerotDetail);